const skidata = [
  { name: 'Configuration', url: 'integrations/skidataConfiguration' },
  { name: 'Devices', url: 'integrations/skidataDevices' },
];

const integrationList = {
  skidata
};

export default integrationList;
