import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllIntegrations } from 'store/actions/Integrations';
import IntlMessages from 'util/IntlMessages';
import { fetchSkidataSettings} from 'services/apiRoutes';
import integrationList from '../../../integrations/integrationsList';
import { INTEGRATION_NAME } from '../../../integrations/integrationsName';
import {updateIntegrationConfiguration} from '../../../store/actions/Integrations';

// import CollapseBoxContainer from '../CollapseBoxContainer';
import CollapseBox from '../CollapseBox';


export default function IntegrationsMenu() {
  const {integrationsList: availableIntegrations, integrationsConfigurations} = useSelector(state => state.integrations);
  const currentCompany = useSelector(state => state.company.currentCompany);
  const isSuperuser = useSelector(state => state.auth.isSuperuser);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSkidataSettings(currentCompany.id).then(data => dispatch(updateIntegrationConfiguration({skidata: data}))).catch((error) => {});
  }, [currentCompany.id, dispatch]);


  useEffect(() => {
    dispatch(fetchAllIntegrations(currentCompany.id));
  }, [currentCompany.id, dispatch]);


  if (!isSuperuser) {
    return null;
  }

  const isActiveIntegration = () => {
    const allIntegrationStatuses = Object.values(availableIntegrations);
    if (!allIntegrationStatuses) {
      return false;
    }
    return allIntegrationStatuses.some(item => item);
  };

  if (!isActiveIntegration()) {
    return null;
  }

  const integrations = () => Object.keys(integrationList).map((key) => {

    if (!availableIntegrations[key]) {
      return null;
    }

    const isValidConfiguration = integrationsConfigurations[key] && integrationsConfigurations[key].is_credentials_verified;

    const currentIntegrationAvailableItems = isValidConfiguration ? integrationList[key] : [integrationList[key][0]];

    return (
      <CollapseBox
        key={key}
        items={currentIntegrationAvailableItems}
        collapseLabel={INTEGRATION_NAME[key] || key}
        iconStyle="zmdi-dns"
          />
    );
  });
  // return (
  //   <ul className="nav-menu">
  //     <li className="nav-header">
  //       <span><IntlMessages id="sidebar.integration" /></span>
  //     </li>
  //     <CollapseBoxContainer
  //       items={integrations()}
  //       collapseLabel={<IntlMessages id="sidebar.allIntegrations" />}
  //       />
  //   </ul>
  // );

  return (
    <ul className="nav-menu">
      <li className="nav-header">
        <span><IntlMessages id="sidebar.integration" /></span>
      </li>
      {integrations()}
    </ul>
  );

}
