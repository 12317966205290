import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import s from './index.module.scss';

export default function CollapseBox({ items, collapseLabel, iconStyle = 'zmdi-view-dashboard' }) {
  const collapseBox = useRef(null);
  const location = useLocation();
  const currentCompany = useSelector(state => state.company.currentCompany);


  useEffect(() => {
    collapseBox.current.classList.remove('open', s.activeParentElement);
    const path = location.pathname.slice(1);
    const subPath = path.split('/');

    if (subPath.length >= 4 && collapseBox.current) {
      const isCollapsedItemMenu = items.some((item) => {

        let url = item.url;

        const isCompositeUrl = item.url.includes('/');

        if (isCompositeUrl) {
          url = item.url.split('/')[1];
        }

        const isCollapsedMenu = (url === subPath[3]) || (url === subPath[4]);

        return isCollapsedMenu;
      });
      if (isCollapsedItemMenu) collapseBox.current.classList.add('open', s.activeParentElement);
    }

    document.activeElement.blur();
  }, [location.pathname, items]);

  const getNavLink = (companyId, item) => `/app/companies/${companyId}/${item}`;

  const toggleShowMenu = () => {
    if (collapseBox.current.classList.contains('open', s.activeParentElement)) {
      collapseBox.current.classList.remove('open', s.activeParentElement);
      return;
    }
    collapseBox.current.classList.add('open', s.activeParentElement);
  };


  return (
    <li className="menu collapse-box" ref={collapseBox}>
      <Button onClick={toggleShowMenu}>
        <i className={`zmdi ${iconStyle} zmdi-hc-fw`} />
        <span className="nav-text">{collapseLabel}</span>
      </Button>

      <ul className="sub-menu">
        {items.map(item => (
          <li key={item.url}>
            <NavLink className="prepend-icon" to={getNavLink(currentCompany.id, item.url)}>
              <span className="nav-text">{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
}
